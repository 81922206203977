.loaders {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.loaders .loader {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 20;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px);
  }

  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px);
  }

  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px);
  }

  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-50px, 50px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px);
  }

  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-25px, 50px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px);
  }

  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px);
  }

  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

.ball-triangle-path {
  position: relative;
  -webkit-transform: translate(-29.994px, -37.50938px);
  transform: translate(-29.994px, -37.50938px);
}

.ball-triangle-path>div:nth-child(1) {
  -webkit-animation-name: ball-triangle-path-1;
  animation-name: ball-triangle-path-1;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ball-triangle-path>div:nth-child(2) {
  -webkit-animation-name: ball-triangle-path-2;
  animation-name: ball-triangle-path-2;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ball-triangle-path>div:nth-child(3) {
  -webkit-animation-name: ball-triangle-path-3;
  animation-name: ball-triangle-path-3;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ball-triangle-path>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  width: 40px;
  height: 40px;
  /* background-color:#4F85E2 ; */
  border-radius: 100%;
  /* border: 1px solid #4F85E2; */
}

.ball-triangle-path>div:nth-of-type(1) {
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: #66dc0c;
  border: 1px solid #66dc0c;
}

.ball-triangle-path>div:nth-of-type(2) {
  left: 25px;
  width: 40px;
  height: 40px;
  background-color: #fb9b0d;
  border: 1px solid #fb9b0d;
}

.ball-triangle-path>div:nth-of-type(3) {
  top: 50px;
  left: 50px;
  width: 40px;
  height: 40px;
  background-color: #4282c4;
  border: 1px solid #4282c4;
}

.loaderspin {
  animation: lightboxLoader infinite 2s linear;
  margin-bottom: 20px;
}

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}