@media only screen and (max-width: 768px) {
    .custom-theme-style {
        .signin-signup-layout {
            height: auto;
            padding-top: 25px;


            .signup-signin-header {
                margin-top: 30px;
            }
        }

        .content-text {
            text-align: center;

            h1 {
                font-size: 30px;
            }
        }

        h1 {
            font-size: 34px;
        }

        h2 {
            font-size: 29px;
        }

        h3 {
            font-size: 26px;
        }

        h4 {
            font-size: 23px;
        }

        h5 {
            font-size: 19px;
        }

        h6 {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .custom-theme-style {
        .signin-signup-layout {

            padding-top: 20px;


            .signup-signin-header {
                margin-top: 15px;
                background: none;
                box-shadow: none;
                padding: 0px 15px;
                border-radius: 0;
                border-top: 2px solid var(--lightgray);
                padding-top: 15px;

                h4 {
                    font-weight: 500;
                    font-size: 24px;
                }
            }

            .content-text {

                h1 {
                    font-size: 27px;
                    font-weight: 600;
                }
            }
        }


    }
}