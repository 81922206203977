@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primarycolor: #2b58bf;
  --secondarycolor: #024b92;
  --primaryfontcolor: #201a2d;
  --secondaryfontcolor: rgb(82 82 82);
  --primaryfontfamaly: "Poppins", sans-serif;
  --secondaryfontfamaly: "Poppins", sans-serif;
  --primaryfontsize: 14px;
  --navbarBackground: #ffffff;
  --topnavbarFontColor: #4d4b55;
  --sideNavBackgroungColor: #ffffff;
  --sideNavBarMainMenu: #4d4b55;
  --sideNavBarSubMenue: #79777f;
  --mainBackground: url(../images/banner-bg.svg), #fff;
  --mainBackgrounddarklightfont: #4d4b55;
  --navigationmenubariconwidth: 27px;
  --navigationmenubariconfontsize: 16px;
  --letterspacing: "0.2px";
  --primaryfontweight: 400;
  --secondaryfontweight: 500;
  --lightbgcolor: #f4faff;
  --whitecolor: #fff;
  --inputborder: #d2dbfc;
  --boxshadow: 0px 0px 6px 0px #4042441a;
  --liteboxshadow: 0px 0px 0px 5px #ffffff6b;
  --lightblue: #eef3ff;
  --inputbg: #f8f9fd;
  --placeholdercolor: #9c97aa;
  --opasityzero: #2b58bf00;
  --gray: #ddd;
  --lightgray: #eee;
  --activegreen: #acffd7;
  --greencolor: #02c064;
  --greencolorhover: #02c666;
  --greenlight: #e5fff2;
  --redcolor: #ef5350;
  --redcolorhover: #df3d3a;
  --erroralart: #fff5f5;
  --erroralartborder: #ffdcdc;
  --notecolor: #f3d86d;
  --speed: 0.5s;
  --boxshadow: 0px 0px 6px 0px rgba(64, 66, 68, 0.1019607843);
}

body {
  font-family: var(--primaryfontfamaly);
  position: relative;
  color: var(--primaryfontcolor);
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  font-family: var(--primaryfontfamaly);
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

.mb-10 {
  margin-bottom: 10px;
}

input {
  font-family: var(--primaryfontfamaly);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

.custom-bg-wrapper {
  min-height: 100vh;
  background: var(--mainBackground);
  background-size: cover;
  background-attachment: fixed;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.custom-dilog-style {
  p {
    font-size: 14px;
    margin-bottom: 10px;

    span {
      font-weight: 600;
    }

    a {
      color: var(--primarycolor);
    }
  }

  h6 {
    font-weight: 600;
    margin-bottom: 5px;
  }

  ul {
    margin-left: 16px;
    margin-top: 5px;
  }

  li {
    margin-bottom: 5px;
  }

  .MuiButton-contained {
    background: var(--primarycolor);
    box-shadow: none;

    &:hover {
      background: var(--secondarycolor);
      box-shadow: none;
    }
  }
}

.custom-theme-style {
  .phone-input {
    display: flex;
    width: 100%;

    .phone-country {
      width: 150px;

      .flag-country {
        display: flex;
        align-items: center;
        padding-top: 8px;
        cursor: pointer;

        &:hover {
          background-color: var(--inputbg);
        }

        .flag {
          width: 45px;
          padding-left: 10px;

          img {}
        }

        span {
          margin-top: -5px;
          width: 100%;
          padding-left: 8px;
          text-align: left;
          font-size: 14px;
        }
      }

      .MuiAutocomplete-listbox {
        max-height: 23vh;
      }

      .MuiAutocomplete-listbox::-webkit-scrollbar {
        width: 2px;
      }
    }

    .phone-number {
      width: 100%;
      text-align: left;
      padding-left: 7px;
    }
  }

  input[type="file"]::file-selector-button {
    border: 0px solid;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: var(--primarycolor);
    color: var(--whitecolor);
    transition: 1s;
    font-family: var(--primaryfontfamaly);
  }

  input[type="file"]::file-selector-button:hover {
    background-color: var(--secondarycolor);
    border: 0px solid;
  }

  .MuiOutlinedInput-root {
    color: var(--primaryfontcolor);
    background-color: var(--inputbg);
    border-radius: 4px;
    font-weight: 400;
    margin-bottom: 12px;

    input {
      font-size: 14px;
      font-weight: 400;
    }

    input::placeholder {
      color: var(--secondaryfontcolor);
      opacity: 0.8;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--inputborder);
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        padding-left: 4px;

        svg {
          font-size: 18px;
        }
      }
    }
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--primarycolor);
    }
  }

  .check-box-custom {
    text-align: left;
    padding-bottom: 12px;
    margin-top: 3px;

    .MuiSvgIcon-root {
      color: var(--secondaryfontcolor);
    }

    .Mui-checked {
      svg {
        color: var(--primarycolor);
      }
    }

    svg {
      font-size: 17px;
    }

    .MuiTypography-root {
      p {
        font-size: 14px;

        a {
          color: var(--primarycolor);
          text-decoration: none;

          &:hover {
            color: var(--secondarycolor);
          }
        }
      }
    }

    .MuiButtonBase-root {
      padding: 0;
      padding-left: 10px;
      padding-right: 5px;
    }
  }

  .MuiFormHelperText-root {
    padding: 0;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 4px;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--redcolor);
  }

  .MuiButton-colorPrimary {
    background: var(--primarycolor);
    box-shadow: none;
    margin-bottom: 12px;

    &:hover {
      background: var(--secondarycolor);
      box-shadow: none;
    }
  }

  .header_main {
    background: #f6fbff;
    padding: 8px 0;
    padding-top: 10px;
    border-bottom: 1px solid #e2eef8;
    width: 100%;
    position: relative;
    z-index: 1;
    box-shadow: 0px 1px 5px 1px rgba(64, 66, 68, 0.06);

    .logo_img {
      img {
        max-width: 178px;
        // height: 36px;
        // max-height: 35px;
      }
    }
  }

  .signin-signup-layout {
    display: flex;
    height: 89vh;
    align-items: center;

    .signup-signin-header {
      text-align: center;
      padding: 25px 25px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 0px rgba(64, 66, 68, 0.1019607843);

      h6 {
        margin-bottom: 0;
        font-size: var(--primaryfontsize);
        font-weight: 400;
      }

      h4 {
        color: var(--primarycolor);
        font-weight: 600;
      }

      .MuiStepper-root {
        margin-bottom: 13px;
        margin-top: 13px;
        padding-left: 30px;
        padding-right: 30px;

        .MuiStepConnector-root {
          .MuiStepConnector-line {
            border-color: var(--lightgray);
            border-top-width: 3px;
          }
        }

        .MuiSvgIcon-root.Mui-active {
          color: var(--primarycolor);
        }

        .MuiStepLabel-label {
          color: var(--secondaryfontcolor);
          margin-top: 10px;
          font-size: 13px;
        }

        .MuiStepLabel-label.Mui-active {
          color: var(--primaryfontcolor);
        }
      }

      .MuiButton-colorPrimary {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .signUpFields {
      .MuiOutlinedInput-root {
        margin-bottom: 0px !important;
        margin-top: 12px;
      }

      .form-error {
        color: #ef5350;
        text-align: left;
        font-size: 13px;
        //   margin: 5px 0 0;
      }
    }

    .content-text {
      h1 {
        font-size: 42px;
        font-weight: 700;
        color: var(--primaryfontcolor);
        margin-bottom: 3px;

        span {
          color: var(--primarycolor);
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        color: var(--secondaryfontcolor);
      }
    }

    .verify-input-label {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
      display: inline-block;
    }

    .timer-style {
      text-align: right;
      margin-bottom: 10px;
      font-size: 13px;
      margin-top: 10px;

      button {
        background: none;
        color: var(--primarycolor);

        &:hover {
          color: var(--secondarycolor);
        }
      }
    }
  }
}